exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-equipment-js": () => import("./../../../src/pages/equipment.js" /* webpackChunkName: "component---src-pages-equipment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labs-index-js": () => import("./../../../src/pages/labs/index.js" /* webpackChunkName: "component---src-pages-labs-index-js" */),
  "component---src-pages-labs-strapi-lab-title-js": () => import("./../../../src/pages/labs/{strapiLab.Title}.js" /* webpackChunkName: "component---src-pages-labs-strapi-lab-title-js" */),
  "component---src-pages-landing-page-js": () => import("./../../../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-people-index-js": () => import("./../../../src/pages/people/index.js" /* webpackChunkName: "component---src-pages-people-index-js" */),
  "component---src-pages-people-strapi-person-slug-js": () => import("./../../../src/pages/people/{strapiPerson.slug}.js" /* webpackChunkName: "component---src-pages-people-strapi-person-slug-js" */),
  "component---src-pages-posts-index-js": () => import("./../../../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-posts-strapi-post-title-js": () => import("./../../../src/pages/posts/{strapiPost.Title}.js" /* webpackChunkName: "component---src-pages-posts-strapi-post-title-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-strapi-project-name-js": () => import("./../../../src/pages/projects/{strapiProject.Name}.js" /* webpackChunkName: "component---src-pages-projects-strapi-project-name-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

